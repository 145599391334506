/* You can add global styles to this file, and also import other style files */
/* @import "~ng-pick-datetime/assets/style/picker.min.css"; */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

:focus {
	outline: none;
}
.footer-area-v1.footer-area-v2 .ovent-overlay{
    display: none;
}
.book_now{
	margin-top: 20px;
	text-align: center;
}
.header-area-v3 .header-navigation .nav-menu .main-menu ul li.menu-item-has-children>a::after,
.header-navigation .nav-container.breakpoint-on .nav-menu .main-menu ul li.menu-item-has-children>a::after {
	content: "\f107";
	font-family: "Font Awesome 5 Pro";
	font-weight: 300;
	display: block;
	position: absolute;
	right: 0;
	height: 45px;
	width: 45px;
	top: 0;
	border-left: 0px solid rgba(255, 255, 255, 0.5);
	z-index: 2;
	background: transparent;
	text-align: center;
	line-height: 52px;
	cursor: pointer;
	color: #222;
	font-size: 20px;
}
.header-area-v3 .header-navigation .nav-menu .main-menu ul li.menu-item-has-children>a::after{
	color: #fff;
	line-height: 40px;
	height: 40px;
	width: 40px;
}
.search-modal .modal-dialog{
	transform: translate(-50%, -50%)!important;
}
.search-modal .modal-content .search_btn{
	width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    justify-content: center;
    left: auto;
	align-items:center;
}
.ovent-sidebar .widget-box.search-box .form_group .search_btn i,
.search-modal .modal-content .search_btn i{
    position: relative;
    top: auto;
    right: auto;
}
.counter-area .counter-box .counter-item{
	width: auto;
	height: auto;
}
.counter-area .counter-box .counter-item .chart_1{
	position: relative;
}
.counter-area .counter-box .counter-item .chart_1>div{
	position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%)
}
.blog-details-section .blog-details-wrapper .comment-area .single-comment .comment-img>img,
.ovent-sidebar .widget-box.recent-post .post-item .post-img img,
.events-area .events-info-item img{
	width: 70px;
	height: 70px;
	object-fit: cover;
	border-radius: 50%;
}
.blog-details-section .blog-details-wrapper .comment-area .single-comment .comment-img>img{
	width: 100px;
	height: 100px;
}
.blog-details-section .blog-details-wrapper .comment-area .single-comment:nth-child(odd) .comment-img>img,
.ovent-sidebar .widget-box.recent-post .post-item .post-img img{
	width: 80px;
	height: 80px;
}
/* Pagination */
pagination-template .ngx-pagination {
	margin-top: 20px;
	justify-content: center;
	margin-bottom: 0;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
}
pagination-template .ngx-pagination li.pagination-next,
pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span,
pagination-template .ngx-pagination li.pagination-previous span,
pagination-template .ngx-pagination li a {
	border: 2px solid #fff;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    position: relative;
    margin-left: 7px;
    margin-right: 7px;
    height: 50px;
    width: 50px;
    display: flex;
    line-height: 2;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
pagination-template .ngx-pagination li.current {
	color: #fff;
	border: 2px solid ;
	background-color: #ff5722;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
	position: relative;
    margin-left: 7px;
    margin-right: 7px;
    height: 50px;
    width: 50px;
	display: flex;
    align-items: center;
    justify-content: center;
	line-height: 2;
	text-align: center;
	border-radius: 50%;
}
button:disabled,
button[disabled]{
	background-color: #ff9473;
	opacity: 0.7;
}
pagination-template .ngx-pagination li.pagination-next::after,
pagination-template .ngx-pagination li.pagination-next a:after,
pagination-template .ngx-pagination li.pagination-previous a::before,
pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
.grid-view .categories-box,
.grid-view .recent-post{
	display: none;
}
.shop-details-section .product-info .price span,
.shop-page-section .product-box .product-info .price span{
	margin-left: 7px;
    margin-right: 7px;
	color: #ec398b;
    font-weight: bold;
}
.shop-details-section .product-info .price span + span,
.shop-page-section .product-box .product-info .price span + span{
	color: #497898;
    font-weight: 400;
	text-decoration: line-through;
}
.shop-details-section .product-info .price span{
	font-size: 50px;
	margin-right: 20px;
	margin-left: 0;
}
.shop-details-section .product-info .price span + span{
	color: #bfc7d3;
}
.nice-number {
    display: inline-flex;
    justify-content: stretch;
}
.shop-details-section .product-info .discription ul li .nice-number input{
	box-sizing: content-box;
    text-align: center;
    margin: 0;
    vertical-align: middle;
    appearance: none;
}
.shop-details-section .rating li i,
.shop-details-section .discription-area .shop-review-area .review_user .rating li i{
    color: #000637;
}
.shop-details-section .rating li i.active,
.shop-details-section .discription-area .shop-review-area .review_user .rating li i.active {
    color: #ffb400;
}
.ovent-faq-section .faq_wrapper .card .card-header{
	border: none;
    border-radius: 0;
    background: transparent;
    padding: 0;
}
.ovent-faq-section .faq_wrapper .card .card-header .btn-link{
	text-align: left;
    width: 100%;
}
.ovent-faq-section .faq_wrapper .card .card-header .btn-link:hover,
.ovent-faq-section .faq_wrapper .card .card-header .btn-link:focus{
	text-decoration: none;
	box-shadow: none;
}
/* Count down */

.countdown {
	justify-content: space-between!important;
	flex-wrap: wrap;
}
.countdown .measurements {
	display: inline-block;
	height: 250px;
	width: 250px;
	border: 10px solid #ffffff24;
	border-radius: 50%;
	position: relative;
	z-index: 2;
	padding-top: 75px;
	flex: unset!important;
	text-align: center;
}
.countdown .measurements::before {
	position: absolute;
	content: '';
	height: 108%;
	width: 108%;
	border-radius: 50%;
	border: 10px solid transparent;
	left: -10px;
	top: -10px;
	z-index: -1;
	border-left-color: #ec398b;
	border-right-color: #ec398b;
	transform: rotate(-45deg);
}
.countdown .measurements .measurements-number {
	font-size: 50px;
	font-family: "Montserrat", sans-serif;
	color: #fff;
	line-height: 50px;
	font-weight: 700;
}
.countdown .measurements .measurements-text {
	font-weight: normal;
    color: #fff;
    font-size: 14px;
	font-family: "Montserrat", sans-serif;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.countdown .measurements {
		height: 200px;
		width: 200px;
		padding-top: 50px;
	}
	.countdown .measurements::before {
		height: 110%;
		width: 110%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.countdown .measurements {
		height: 150px;
		width: 150px;
		padding-top: 30px;
	}
	.countdown .measurements::before {
		height: 114%;
		width: 117%;
	}
}
@media (max-width: 767px) {
	.countdown {
		justify-content: center!important;
	}
	.countdown .measurements {
		height: 200px;
		width: 200px;
		margin: 20px 0;
		padding-top: 50px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.countdown .measurements {
		height: 200px;
		width: 200px;
		margin: 20px;
		padding-top: 50px;
	}
	.countdown .measurements::before {
		height: 111%;
		width: 111%;
	}
	.countdown {
		justify-content: space-between!important;
	}
}
.inaild_no{
    font-size: 15px;
    color: red;
}

.date-ico i {
	position: absolute;
	top: 54px !important;
    right: 7px !important;
    font-size: 29px;
    cursor: pointer;
  }

  .toast-msg{
	margin: 0 auto;
	position: relative;
	text-align: center;
	width: 470px !important;
  }
  ngb-toast{
	position: absolute;
	/* right:40%; */
	top: 50%;
	z-index: 99999999999;
	text-align: center;
	width: 470px !important;
  }

  .form_group {
    position: relative;
    margin-bottom: 20px !important;
}
.form_control{
    margin-bottom: 0px !important;
}
.my-translated-paragraph{
  white-space: pre-wrap;
}

.required_field{
	font-size: 18px;
	position: relative;
    top: 12px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.slick-slider {
    width: 88%;
    margin: auto;
    background: rgb(14, 13, 13);
  }
  
body .slick-prev, 
body .slick-next {
    height: 35px;
    width: 30px;
	border-radius: 20%;
    background: transparent !important;
    z-index: 100;
}
.slick-prev:before, .slick-next:before {
    font-size: 32px;
	font-weight: bold;
    color: #fba339;
}
